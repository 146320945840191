import * as React from 'react';
import { Autocomplete, createFilterOptions } from '@mui/material';
import { isEmpty } from 'lodash-es';
import * as Sentry from '@sentry/browser';

import MUITextField, {
  StandardTextFieldProps as MUITextFieldProps
} from '@mui/material/TextField';

import { validate } from './validate';
import { Variable } from 'models/project';
import { TextFieldSchema } from 'models/form/schema';
import { FieldValidationError } from 'models/form/validate';
import { ChangeFieldHandler } from 'components/form/schemaFieldBase';
import { WithStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { FilterOptionsState } from '@mui/material/useAutocomplete/useAutocomplete';

const styles = () => ({
  flex: {
    display: 'flex',
    width: '100%'
  },
  autoComplete: {
    flexGrow: 1
  }
});

const hookstyles = makeStyles(styles());

export interface TextFieldProps extends MUITextFieldProps {
  variables?: Variable[];
  options?: string[];
  schema: TextFieldSchema; // | SwitchFieldSchema;
  errors: FieldValidationError;
  onChangeField: ChangeFieldHandler;
  forcePopupIcon?: boolean;
  detailedHelpTooltip?: React.ReactNode;
}

interface TextFieldState {
  value: string;
}

function getSuggestions({
  options: suggestions,
  createAutoCompleteFilterOptions,
  filterOptionsState,
  value
}: {
  options: AutoCompleteOption[] | undefined;
  createAutoCompleteFilterOptions: ReturnType<
    typeof createFilterOptions<AutoCompleteOption>
  >;
  filterOptionsState: FilterOptionsState<AutoCompleteOption>;
  value?: string | null;
}) {
  if (suggestions == undefined) {
    return [];
  }

  if (!value) {
    return suggestions;
  }

  // カンマ区切りの場合、最後の文字列で補完する
  const inputValues = value.split(',');
  const inputValue = inputValues[inputValues.length - 1].trim().toLowerCase();
  const inputLength = inputValue.length;

  if (inputLength === 0) {
    return suggestions;
  }

  return createAutoCompleteFilterOptions(
    suggestions.filter((suggestion) => {
      if (suggestion == undefined || suggestion.value == undefined) {
        Sentry.captureException('Suggestion value is invalid', {
          extra: { suggestions }
        });
        return false;
      }
      return true;
    }),
    filterOptionsState
  );
}

const TextField: React.FunctionComponent<TextFieldProps> = ({
  onChangeField,
  schema,
  errors,
  classes,
  detailedHelpTooltip,
  ...props
}) => {
  if (schema.detailHelpName && detailedHelpTooltip != undefined) {
    props.fullWidth = false;
  } else if (schema.fullWidth) {
    props.fullWidth = schema.fullWidth;
  } else {
    props.fullWidth = true;
  }

  props.label = schema.placeholder;
  const readOnly = schema.readonly != undefined ? schema.readonly : false;
  errors = Array.isArray(errors) ? errors : [];

  const styleClasses = hookstyles();

  return (
    <div className={styleClasses.flex}>
      <MUITextField
        className={styleClasses.autoComplete}
        onChange={(ev) =>
          onChangeField(
            schema.key,
            ev.target.value,
            validate(ev.target.value, schema.validate)
          )
        }
        error={errors.length > 0}
        helperText={errors.length > 0 ? errors[0] : ''}
        InputProps={{
          readOnly
        }}
        {...props}
        variant={schema.variant}
      />
      {detailedHelpTooltip}
    </div>
  );
};

interface AutoCompleteOption {
  label: string;
  value: string;
}

class AutoCompleteTextField extends React.Component<
  TextFieldProps & WithStyles<typeof styles>,
  TextFieldState & {
    createAutoCompleteFilterOptions: ReturnType<
      typeof createFilterOptions<AutoCompleteOption>
    >;
  }
> {
  static getDerivedStateFromProps(
    nextProps: TextFieldProps,
    prevState: TextFieldState
  ) {
    if (nextProps.value !== prevState.value) {
      return { value: nextProps.value };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      createAutoCompleteFilterOptions: createFilterOptions<AutoCompleteOption>()
    };
  }

  handleChangeAutocompleteValue = (_, option: AutoCompleteOption) => {
    // autocompleteでのinputの場合
    if (!option) {
      return;
    }
    const { value } = this.state;
    const { schema, onChangeField } = this.props;
    const selectedValue = option.value;

    // カンマで区切られてた場合、カンマの後の文字列がautocompleteの対象になる
    const lastCommaIndex = value.lastIndexOf(',');
    const newInput =
      lastCommaIndex === -1
        ? selectedValue
        : value.substring(0, lastCommaIndex + 1) + selectedValue;

    this.setState({ value: newInput });
    onChangeField(schema.key, newInput, validate(newInput, schema.validate));
  };

  handleChangeInputValue = (ev: React.ChangeEvent<HTMLInputElement>) => {
    // autocomplete以外のinputの場合
    const { schema, onChangeField } = this.props;
    this.setState({ value: ev.target.value });
    onChangeField(
      schema.key,
      ev.target.value,
      validate(ev.target.value, schema.validate)
    );
  };

  render() {
    const { value, createAutoCompleteFilterOptions } = this.state;
    const {
      schema,
      forcePopupIcon,
      variables,
      options,
      errors,
      detailedHelpTooltip,
      classes
    } = this.props;

    let suggetions: AutoCompleteOption[] = [];
    if (options != undefined) {
      suggetions = suggetions.concat(
        options.map((o) => ({ label: o, value: o }))
      );
    }
    if (variables != undefined) {
      suggetions = suggetions.concat(
        variables.map((v) => ({
          label: `変数 ${v.name}`,
          value: `$${v.name}`
        }))
      );
    }
    return (
      <div className={classes.flex}>
        <Autocomplete
          className={classes.autoComplete}
          style={{ flex: '1 1 auto' }}
          forcePopupIcon={forcePopupIcon}
          disableClearable={false}
          freeSolo={true}
          value={value}
          onChange={this.handleChangeAutocompleteValue}
          options={suggetions}
          filterOptions={(
            options: AutoCompleteOption[],
            filterOptionsState: FilterOptionsState<AutoCompleteOption>
          ) =>
            getSuggestions({
              options,
              createAutoCompleteFilterOptions,
              filterOptionsState,
              value
            })
          }
          getOptionLabel={(option: AutoCompleteOption | string) =>
            typeof option === 'string' ? option : option.label
          }
          isOptionEqualToValue={(option: AutoCompleteOption, val: string) =>
            option.value === val
          }
          renderInput={(params) => (
            <MUITextField
              {...params}
              onChange={this.handleChangeInputValue}
              label={schema.placeholder}
              fullWidth={true}
              InputProps={{
                ...params.InputProps
              }}
              inputProps={{
                ...params.inputProps,
                style: {
                  padding: '6px 0'
                }
              }}
              error={errors.length > 0}
              helperText={errors.length > 0 ? errors[0] : ''}
              variant={schema.variant}
            />
          )}
          openOnFocus={true}
        />
        {detailedHelpTooltip}
      </div>
    );
  }
}

const StyledComponent = withStyles(styles)(AutoCompleteTextField);

export default function (props: TextFieldProps) {
  if (!isEmpty(props.variables) || !isEmpty(props.options)) {
    const { ref, classes, ...rest } = props;
    return <StyledComponent {...rest} />;
  }

  return <TextField {...props} />;
}
